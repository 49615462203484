import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Selfserve1 } from '../../../components/images/support/workflows/Selfserve1.generated';
import { Selfserve2 } from '../../../components/images/support/workflows/Selfserve2.generated';
import { Selfserve3 } from '../../../components/images/support/workflows/Selfserve3.generated';
import { Selfserve4 } from '../../../components/images/support/workflows/Selfserve4.generated';
import { Selfserve5 } from '../../../components/images/support/workflows/Selfserve5.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "create-a-self-service-workflow"
    }}>{`Create a Self Service Workflow`}</h1>
    <p>{`Self-service workflows allow members to complete a workflow without the need for an `}<strong parentName="p">{`Admin`}</strong>{` or `}<strong parentName="p">{`Supervisor`}</strong>{` to assign it.`}</p>
    <p>{`Self-service workflows are great for tasks that:`}</p>
    <ul>
      <li parentName="ul">{`Occur regularly and;`}</li>
      <li parentName="ul">{`Should be completed by team members when they choose.`}</li>
    </ul>
    <p>{`Let's get started!`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Workflows`}</strong>{` menu.`}</li>
    </ol>

    <Selfserve1 mdxType="Selfserve1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the menu for the workflow you want to make self service.`}</li>
    </ol>

    <Selfserve2 mdxType="Selfserve2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Make self-service`}</strong>{`.`}</li>
    </ol>

    <Selfserve3 mdxType="Selfserve3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Confirm you are making self service.`}</li>
    </ol>

    <Selfserve4 mdxType="Selfserve4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`You can now see the workflow has the `}<strong parentName="li">{`Self-service`}</strong>{` tag. Members will now be able to complete this flow from their dashboard without the need for the workflow to be assigned to them.`}</li>
    </ol>

    <Selfserve5 mdxType="Selfserve5" />
    <p>{`The following guide shows how users can start a self-service workflow`}</p>

    <SupportFooter linkTo="/support/workflows/start-self-service-workflow" linkText="How to start a self-service workflow" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      